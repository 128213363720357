<template>
  <div id="app">
    <Scene />
  </div>
</template> 

<script>
import Scene from "./components/Scene.vue";

export default {
  name: "App",
  components: {
    Scene,
  },
}; 
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
    // width: 100%;
  // height: 100%;
}

</style>
