import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  "a-entity",
  "a-sky",
  "a-image",
  "a-scene",
  "a-assets",
  "a-circle",
  "a-ocean",
];

AFRAME.registerComponent("player-color", {
  schema: {
    color: { type: "color", is: "uniform", default: "blue" },
    elLoaded: { type: "bool", is: "uniform", default: "blue" },
  },
  init: function() {
    var data = this.data; // Component property values.
    var el = this.el; // Reference to the component's entity.

    el.addEventListener("model-loaded", () => {
      data.elLoaded = true;
    });
  },

  update: function() {
    var data = this.data; // Component property values.
    var el = this.el; // Reference to the component's entity.
    var color = new THREE.Color(data.color);
    if (data.event) {
      el.addEventListener(data.event, function() {});
    } else {
      if (data.elLoaded) {
        console.log(el.object3D);
        if (el.object3D.children.length > 0) {
          el.object3D.children[0].children[0].children[1].material.color = color;
        }
      }
    }
  },
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
