<template>
  <div class="scene">
    <!-- background="color: #353844" -->
    <div class="top-ui">
      <input
        type="color"
        id="body"
        name="body"
        v-model="playerColor"
        style="opacity: 0"
      />
      <label
        class="btn-three color"
        for="body"
        :style="`background: ${playerColor}`"
        >웃 <br />
        color</label
      >
      <div class="btn-three name-changer" role="button">
        <label for="name" class="name-label">name</label><br />
        <input type="text" name="name" id="" v-model="name" maxlength="13" />
      </div>
      <div class="btn-three players">
        Players <br />
        <span v-if="occupants">{{ occupants.length }}</span
        ><span v-else>0</span>
      </div>
    </div>
    <a-scene vr-mode-ui="enabled: false">
      <a-entity
        environment="preset: forest; seed: 4; fog: 0.3'; ground: none; dressing: none; playArea: 0.2"
        position="0 -3 0"
      ></a-entity>
      <a-ocean
        width="50"
        depth="9"
        position="0 -2.3 0"
        density="10"
        speed="0.1"
        scale="1.05 1 1"
      ></a-ocean>
      <a-entity
        light="type: point; intensity: 0.75; distance: 20; decay: 10; color: orange;"
        position="11.581 -0.378 13.067"
        animation="property: light.intensity;  to:1.5; dur: 2000; loop:true; easings: easeInOutSine; dir: alternate; autoplay: true"
      ></a-entity>
      <a-entity v-if="myUser">
        <a-entity v-for="(user, index) in occupants" :key="index">
          <a-entity
            v-if="myUser !== user.id"
            :animation="`property: position; to: ${user.pos.x} ${user.pos.y} ${user.pos.z}; loop: false; dur: 1000`"
          >
          <!-- <a-entity look-at="#camera" position="0 2.5 0" :text="`font: mozillavr; value: ${user.name}; negate: true` "></a-entity> -->

            <a-text
              :value="user.name"
              look-at="#camera"
              position="0 2.3 0"
              align="center"
              text.negate="true"
              color="yellow"
            ></a-text>
            
            <!-- :position="`${user.pos.x} ${user.pos.y} ${user.pos.z}`" -->
            <a-entity
              :rotation="`${user.rot.x} ${user.rot.y} ${user.rot.z}`"
              v-if="myUser !== user.id"
              position="0 1.4 0.1"
              class="userCam"
            >
              <!-- :animation="`property: rotation; to: ${user.rot.x} ${-user.rot
                .y} ${user.rot.z}; loop: false; dur: 300`" -->
              <a-entity
                ref="user"
                :class="`${user.id}`"
                scale="0.7 0.7 0.7"
                animation-mixer
                gltf-model="cuteCubeRed.glb"
                :player-color="`color: ${user.color}`"
                rotation="0 -90 0"
              ></a-entity>
            </a-entity>
          </a-entity>
        </a-entity>
      </a-entity>
      <a-entity
        movement-controls="constrainToNavMesh: true"
        ref="rig"
        id="rig"
        position="0 0 10"
      >
        <a-entity
          position="0 1.8 0.1"
          ref="camera"
          id="camera"
          camera
          look-controls
          ><a-entity
            class="character"
            scale="0.9 0.9 0.9"
            position="0 -1 2"
            animation-mixer
            gltf-model="cuteCubeRed.glb"
            rotation="0 -90 0"
          ></a-entity
        ></a-entity>
      </a-entity>

      <a-entity
        animation-mixer
        gltf-model="land2.glb"
        position="0 -9 0"
      ></a-entity>
      <a-entity
        gltf-model="navmesh2.glb"
        nav-mesh
        position="0 -8 0"
        visible="false"
      ></a-entity>
    </a-scene>
  </div>
</template>

<script>
import io from "socket.io-client";

export default {
  name: "Scene",
  data: () => {
    return {
      playerColor: "#e66465",
      name: "Anony",
      socket: null,
      occupants: null,
      myUser: null,
      refs: null,
    };
  },
  created: function () {
    this.socket = io("https://excited-spicy-sing.glitch.me");
    this.socket.on("myUser", (data) => {
      this.myUser = data;
    });
  },
  mounted: function () {
    var rig = this.$refs.rig;
    var camera = this.$refs.camera;
    var pos = rig.object3D.position;
    var rot = camera.getAttribute("rotation");

    document.addEventListener("keydown", (e) => {
      let k = e.keyCode;
      if (
        k === 68 ||
        k === 83 ||
        k === 65 ||
        k === 87 ||
        k === 37 ||
        k === 38 ||
        k === 39 ||
        k === 40
      ) {
        this.socket.emit("location", pos, rot, this.playerColor);
      }
    });
    // this.socket.on("myUser", (data) => {
    //   this.myUser = data;
    // });
    this.socket.on("occupants", (data) => {
      this.occupants = data;
    });
    window.addEventListener("mousedown", (e) => {
      this.socket.emit(
        "location",
        pos,
        camera.getAttribute("rotation"),
        this.playerColor
      );
    });
    window.addEventListener("mouseup", (e) => {
      this.socket.emit(
        "location",
        pos,
        camera.getAttribute("rotation"),
        this.playerColor
      );
    });
  },
  methods: {
    areaClick: function () {
      let area = this.$refs.area;
      this.isMain = !this.isMain;
    },
  },
  watch: {
    playerColor: function (val) {
      var rig = this.$refs.rig;
      var camera = this.$refs.camera;
      var pos = rig.object3D.position;
      var rot = camera.getAttribute("rotation");
      this.socket.emit("location", pos, rot, this.playerColor);
    },
    name: function () {
      this.socket.emit("name", this.name);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$blue: #3498db;
.ui-btn {
  cursor: pointer;
  background: $blue;
  color: white;
  border: white 3px solid;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.top-ui {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  .ui-btn {
    margin-right: 10px;
  }
}
$yellow: #ffe800;
$brown: #404040;
$blue: #1cd3f8;
$angle: 8px;
$angle-o: 4px;

.btn-three {
  margin-right: 20px;
  &.color {
    padding: 7px;
    input {
      height: 22px;
      width: 80px;
    }
  }
  &.name-changer {
    padding: 7px;
    input {
      height: 22px;
      width: 80px;
    }
  }
  &.players {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $blue !important;
    padding: 10px;
    input {
      height: 22px;
    }
    padding: 7px;
  }
}
.btn-three {
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-color: $yellow;
  text-transform: uppercase;
  color: white;
  font-family: arial;
  letter-spacing: 1px;

  box-shadow: -6px 6px 0 $brown;
  text-decoration: none;

  &:hover {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 $brown;

    &::after {
      top: 1px;
      left: -2px;
      width: $angle-o;
      height: $angle-o;
    }

    &::before {
      bottom: -2px;
      right: 1px;
      width: $angle-o;
      height: $angle-o;
    }
  }

  &::after {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    top: 2px;
    left: -4px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;
  }

  &::before {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    bottom: -4px;
    right: 2px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;
  }
}

.btn-three {
  position: relative;
}
.name-label {
  margin-right: 4px;
  color: $brown;
  width: 15px;
}

label:active {
  top: 6px;
  left: -6px;
  box-shadow: none;

  &:before {
    bottom: 1px;
    right: 1px;
  }

  &:after {
    top: 1px;
    left: 1px;
  }
}
</style>
